import * as Client from '@inway/webauthn-client';
import { PublicKeyCredentialDescriptorOnWire } from '@inway/webauthn-client';
import { RegistryEntry } from './types';

export type AssertionArgs = {
  userVerification: string;
  allowCredentials?: PublicKeyCredentialDescriptorOnWire[];
};

export default async function assertion(
  options: AssertionArgs,
  username: string,
  registry: RegistryEntry[],
  modifiers?: URLSearchParams
) {
  const allowCredentials = registry
    .filter((entry) => entry.username === username)
    .map((entry) => ({
      id: entry.id,
      type: entry.type as 'public-key',
      transports: entry.transports,
    }));

  const optionsRequest = await fetch(
    '/api/get'.concat(modifiers ? '?'.concat(modifiers.toString()) : ''),
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({ ...options, allowCredentials }),
      credentials: 'include',
    }
  );
  if (!optionsRequest.ok)
    throw new Error(
      `Invalid response to options request ${optionsRequest.status}`
    );

  const getOptions = await optionsRequest.json();
  const credentials = await Client.requestCredential(getOptions);

  const assureRequest = await fetch(
    '/api/assure'.concat(modifiers ? '?'.concat(modifiers.toString()) : ''),
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({ credentials, getOptions, username, registry }),
      credentials: 'include',
    }
  );

  if (!assureRequest.ok)
    throw new Error(
      `Invalid response for attestation request ${assureRequest.status}`
    );

  const { result, credentialSource } = await assureRequest.json();

  return { result, options, credentialSource };
}
