import React from 'react';
import Image from 'next/image';

function GitHubLink(props: React.ComponentProps<'a'>) {
  return (
    <a {...props} href="https://github.com/inway/webauthn">
      <div
        className="github-logo"
        style={{
          height: '1em',
          width: '1em',
        }}
      />
    </a>
  );
}

export default GitHubLink;
