import * as Client from '@inway/webauthn-client';
import { RegistryEntry } from './types';

export interface RegistrationArgs {
  username: string;
  password: string;
  attestation: string;
  authenticatorSelection: {
    authenticatorAttachment?: string;
    requireResidentKey: boolean;
    residentKey: string;
    userVerification: string;
  };
}

export default async function registration(
  options: RegistrationArgs,
  registry: RegistryEntry[],
  modifiers?: URLSearchParams
) {
  const excludeCredentials = registry
    .filter((entry) => entry.username === options.username)
    .map((entry) => ({
      id: entry.id,
      type: entry.type as 'public-key',
      transports: entry.transports,
    }));

  const optionsRequest = await fetch(
    '/api/create'.concat(modifiers ? '?'.concat(modifiers.toString()) : ''),
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({ ...options, excludeCredentials }),
      credentials: 'include',
    }
  );
  if (!optionsRequest.ok)
    throw new Error(
      `Invalid response to options request ${optionsRequest.status}`
    );

  const createOptions = await optionsRequest.json();
  const credentials = await Client.createCredential(createOptions);

  const attestRequest = await fetch(
    '/api/attest'.concat(modifiers ? '?'.concat(modifiers.toString()) : ''),
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({ credentials, createOptions }),
      credentials: 'include',
    }
  );

  if (!attestRequest.ok)
    throw new Error(
      `Invalid response for attestation request ${attestRequest.status}`
    );

  const result = await attestRequest.json();

  return { result, options };
}
