import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import GitHubLink from './GitHubLink';
import useRegistry from '../hooks/useRegistry';

export interface HeaderProps {
  version?: string;
}

export default function Header({ version }: HeaderProps) {
  const pathname = usePathname();
  const [registry, updateRegistry] = useRegistry();

  return (
    <header className="d-flex flex-wrap py-3 justify-content-between align-items-center align-content-between">
      <div className="mb-2">
        <Image
          src="/shield-halved-solid.svg"
          width={32}
          height={32}
          alt="Shield"
        />
      </div>

      <div className="d-flex ms-2 mb-2 justify-items-start align-items-baseline">
        <pre className="fs-4 mb-0">@inway/webauthn</pre>
        {version && <code className="ms-2">v{version}</code>}
      </div>

      <GitHubLink className="ms-2 mb-2 align-self-center" target="_blank" />

      <nav className="ms-auto mb-2 align-self-center nav nav-pills">
        <Link
          href="/"
          className={`nav-link ${pathname === '/' ? 'active' : ''} `}
        >
          Registrations
          {registry?.length > 0 && (
            <span className="ms-2 badge text-bg-secondary">
              {registry.length}
            </span>
          )}
        </Link>
        <Link
          href="/login"
          className={`nav-link ${pathname === '/login' ? 'active' : ''}`}
        >
          Login
        </Link>
      </nav>
    </header>
  );
}
