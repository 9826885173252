/* eslint-disable react-hooks/rules-of-hooks */
import { useLocalStorage } from '@uidotdev/usehooks';
import { useCallback } from 'react';
import { RegistryEntry } from '../types';

type ClearRegistryAction = {
  type: 'clear';
};

type AddRegistryAction = {
  type: 'add';
  entry: RegistryEntry;
};

type RemoveKeyRegistryAction = {
  type: 'removeKey';
  entry: RegistryEntry;
};
type RemoveUserRegistryAction = {
  type: 'removeUser';
  username: string;
};

type RegistryAction =
  | ClearRegistryAction
  | AddRegistryAction
  | RemoveKeyRegistryAction
  | RemoveUserRegistryAction;

type RegistryState = {
  registry: RegistryEntry[];
};

type RegistryDispatch = (action: RegistryAction) => void;

export default function useRegistry(): [
  RegistryState['registry'],
  RegistryDispatch,
] {
  if (typeof window === 'undefined') {
    return [[], () => {}];
  }

  const [registry, setRegistry] = useLocalStorage<RegistryState['registry']>(
    'registry',
    []
  );

  const dispatch = (action: RegistryAction) => {
    switch (action.type) {
      case 'clear':
        setRegistry([]);
        break;
      case 'add':
        const appended = [...registry, action.entry];
        setRegistry(appended);
        break;
      case 'removeKey': {
        const removed = registry.filter((e) => e.id !== action.entry.id);
        setRegistry(removed);
        break;
      }
      case 'removeUser': {
        const removed = registry.filter((e) => e.username !== action.username);
        setRegistry(removed);
        break;
      }
    }
  };

  return [registry, dispatch];
}
